<template>
    <div class="mb-16">
      <v-row class="d-flex justify-center align-center flex-column mt-10">
        <h1 class="arabic  text-center mb-2" style="color: #153D4A;" data-aos="fade-down" data-aos-easing="ease-out-cubic"
         data-aos-duration="900">التسويق الالكتروني والإعلانات</h1>
         <v-divider class="mt-5 mb-5" style="width:40%; background-color:#005f9e; opacity: 30%;"></v-divider>
        <h2 class="arabic  text-center mb-2" style="color: #153D4A;" data-aos="fade-down" data-aos-easing="ease-out-cubic"
         data-aos-duration="900">حول تطبيقك إلى نجاح مع <span style="color: #005F9E;">راقي</span></h2>

         <h4 class="arabic text-center mb-10" style="color: #153D4A;" data-aos="fade-down" data-aos-easing="ease-out-cubic"
         data-aos-duration="900">شريكك المتفاني في كل مرحلة من مراحل انشاء تطبيقك حتى النجاح</h4>
      </v-row>

    <v-row class="d-flex align-center" style="justify-content: space-evenly;" >
        <v-col class="d-flex justify-center align-center flex-column px-0 align-content-center"  md="5" cols="12" sm="12" data-aos="fade-up" data-aos-easing="ease-out-cubic"
       data-aos-duration="800" data-aos-delay="50">
        <div class="d-flex justify-center algin-center flex-column align-center" :class="{'mt-n16': $vuetify.breakpoint.mdAndUp}" >
          <img src="../assets/RAQI Logo HQ.jpg" height="350px" alt="">
            <p class="text-center arabic"  :class="{'px-14': $vuetify.breakpoint.smAndDown}" style="font-size: 1.5rem;" >
                مع فريقنا المتميز في
                <span style="color: #005F9E; font-size: 2rem;">راقي</span>
                ستتأكد من النمو الناجح لتطبيقك
                  نبدأ بتحقيق نجاح مشروعك منذ بداية الفكرة
        </p>
        </div>
        <v-divider v-if="$vuetify.breakpoint.smAndDown" width="250px" class="mt-5"></v-divider>
    </v-col>
      <v-col class="d-flex justify-center align-center flex-column px-0 align-content-center" md="4" cols="12" sm="12" data-aos="fade-up" data-aos-easing="ease-out-cubic"
       data-aos-duration="800" data-aos-delay="100">
        <div class="d-flex justify-center align-center flex-column" >
          <v-stepper
    v-model="e6"
    vertical
  >
    <v-stepper-step
      :complete="e6 > 1"
      step="1"
      @click="e6 = 1"
      class="arabic-text font-weight-bold"
    >
    تقديم استشارات دقيقة لفهم احتياجاتك وأهدافك
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
        class="d-flex justify-center flex-column"
        flat
        height="200px"
      >
    <v-icon color="#005F9E" size="100px">
      mdi-account-question
    </v-icon>
    <p class="arabic-text text-center"> نقوم بتقديم استشارات دقيقة لفهم احتياجاتك وأهدافك قبل بدء تنفيذ المشروع</p>
    </v-card>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 2"
      step="2"
      class="arabic-text font-weight-bold"
      @click="e6 = 2"
    >
    تصميم وتطوير التطبيقات
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
        flat
        class="d-flex justify-center flex-column"
        height="200px"
      >
      <v-icon color="#005F9E" size="100px">
      mdi-cellphone-link
    </v-icon>
    <p class="arabic-text text-center"> نتولى تصميم وتطوير التطبيقات بأحدث التقنيات لضمان أداء ممتاز وتجربة مستخدم سلسة.</p>
    </v-card>

    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 3"
      step="3"
      class="arabic-text font-weight-bold"
      @click="e6 = 3"
    >
  إنشاء لوحة تحكم مخصصة
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card
        flat
        class="d-flex justify-center flex-column"
        height="200px"
      >
      <v-icon color="#005F9E" size="100px">
      mdi-monitor-dashboard
    </v-icon>
    <p class="arabic-text text-center">نقوم بإنشاء لوحة تحكم مخصصة لتمكينك من إدارة تطبيقك بسهولة وفعالية</p>
    </v-card>
    </v-stepper-content>

    <v-stepper-step step="4" @click="e6 = 4" class="arabic-text font-weight-bold">
      حملات إعلانية متجددة ومبتكرة
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-card
        flat
        class="d-flex justify-center flex-column"
        height="200px"
      >
      <v-icon color="#005F9E" size="100px">
      mdi-advertisements
    </v-icon>
    <p class="arabic-text text-center">نبتكر حملات إعلانية متجددة ومبتكرة للترويج للتطبيق وجذب الجمهور المستهدف</p>
    </v-card>

    </v-stepper-content>
  </v-stepper>
        </div>
      </v-col>
    </v-row>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      e6: 1
    }
  }
}
</script>

  <style>
  .sec-gradient-btn:hover{
    background: rgb(1,217,240);
  background: linear-gradient(120deg, rgba(1,217,240,1) 0%, rgba(0,93,164,0.3086484593837535) 90%);
  box-shadow: 0.5px 5px 30px #0197b576;
  -webkit-transition: all .0.1s normal linear !important;
  transition: all .0.1s normal linear !important;
  }
  .sec-btn:hover{
    background: #005f9e;
  background: #005f9e;
  box-shadow: 0.5px 5px 30px #044fa576;
  -webkit-transition: all .0.1s normal linear !important;
  transition: all .0.1s normal linear !important;
  border-color: #005F9E;
  color: white !important;
  }
  .secondary1{
    color: #005F9E;
  }
  </style>
