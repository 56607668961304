<template>
  <div>
    <v-row class="d-flex justify-center align-center mt-5">
      <v-col class="d-flex justify-center align-center flex-column mt-n10"  data-aos="fade-down" data-aos-easing="ease-out-cubic"
     data-aos-duration="900">
        <h4 class="arabic text-centerd  mb-2" style="color: #1D2A3B; opacity: 70%;">أهلاً بكم في موقعنا.</h4>
        <h1 class="arabic text-centerd  mb-n10" style="color: #1D2A3B;">مجالات عمل شركة <span class="secondary1">راقي</span></h1>
        <v-divider class="mt-16 mb-n16" style="width:40%; background-color:#005f9e; opacity: 30%;"></v-divider>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
    <v-col class="d-flex justify-center align-center flex-column align-content-center" :class="{ 'mt-16': $vuetify.breakpoint.mdAndUp, 'pb-0': $vuetify.breakpoint.smAndDown }" md="5" lg="5" xs="12" sm="12">
      <div data-aos="fade-left" data-aos-duration="900" v-for="(card, i) in right_cards" :key="i">
        <v-hover v-slot="{ hover }" >
          <v-card  :class="{ 'md-card-size': $vuetify.breakpoint.mdAndUp, 'sm-card-size': $vuetify.breakpoint.smAndDown }" :color="hover? '#053039' : 'aliceblue'"  elevation="24" class="mx-auto my-12 d-flex justify-center align-center flex-column" style="border-radius: 25px 25px 0px 25px; transition: all .2s normal linear;">

    <v-row class="d-flex justify-center align-center">
        <div v-if="card.label" class="d-flex justify-center align-center mt-10 arabic label" >ينصح به<v-icon class="mr-1" color="white" size="20px">mdi-auto-fix</v-icon></div>
        <v-img alt="mobile" class="mt-10" style="margin-right: -15px; transition: all .2s normal linear" :style="{ 'height': $vuetify.breakpoint.smAndDown ? '134px' : '172px', 'width' : $vuetify.breakpoint.smAndDown ? '152px' : '190px' }" :src="card.img"></v-img>
    </v-row>
    <div>
      <p class="arabic text-centerd mb-0 text-center" :style="{ 'color': hover ? '#b8c3ca' : '#005f9e' }" style=" font-size: small;">
           {{ card.sm_title }}
        </p>
      <p class="arabic text-centerd mb-0 mx-2 text-center" :style="{ 'color': hover ? 'aliceblue' : '#005f9e', 'font-size':$vuetify.breakpoint.smAndDown? 'large' : 'x-large' }" style="letter-spacing: -0.5px; ">
        {{ card.title }}
        <v-icon class="mr-2" size="30px" :style="{ 'color': hover ? '#FFFF00' : '#005f9e', 'opacity': hover ? '100%' : '80%' }">mdi-check-decagram</v-icon></p>
    </div>

    <v-card-text>
      <div class="arabic text-center" :class="{'px-2': $vuetify.breakpoint.smAndDown, 'px-15': $vuetify.breakpoint.mdAndUp}" :style="{ 'color': hover ? '#b8c3ca' : 'gray' , 'font-size':$vuetify.breakpoint.smAndDown? 'x-small' : '' }">
        {{ card.describtion }}
        </div>
    </v-card-text>

    <v-divider height="10px" width="490px" class="card-divider"></v-divider>

    <v-card-title class="arabic text-center mb-n3" :style="{ 'color': hover ? 'aliceblue' : 'black' }">تقنيات عملنا</v-card-title>

    <div>
      <v-chip-group>
        <v-chip class="arabic text-centerd mx-1 ma-0" small dark style="opacity: 70%;" :style="{ 'background-color': hover ? 'gray' : 'black' }">{{ card.chip1 }}</v-chip>

        <v-chip class="arabic text-centerd mx-1 ma-0" small dark style="opacity: 70%;" :style="{ 'background-color': hover ? 'gray' : 'black' }">{{ card.chip2 }}</v-chip>

        <v-chip class="arabic text-centerd mx-1 ma-0" small dark style="opacity: 70%;" :style="{ 'background-color': hover ? 'gray' : 'black' }">{{ card.chip3 }}</v-chip>
      </v-chip-group>
    </div>

    <v-card-actions>
      <v-btn @click="cardValueR(i)" outlined rounded width="200px" height="40px" text class="arabic text-centerd  mb-3"  :style="{ 'color': hover ? 'aliceblue' : '#005f9e', 'border-color': hover ? 'aliceblue' : '#005F9E' }" style="letter-spacing: 0.3px; font-size: 16px;">
        اطلب الآن<v-icon class="mr-1 z" small>mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
          </v-card>
        </v-hover>
      </div>
    </v-col>

    <v-col class="d-flex justify-center align-center flex-column align-content-center" :class="{ 'mt-16': $vuetify.breakpoint.mdAndUp, 'pt-0': $vuetify.breakpoint.smAndDown }" md="5" lg="5" xs="12" sm="12">
    <div data-aos="fade-right" data-aos-duration="900" v-for="(card, i) in left_cards" :key="i">
      <v-hover v-slot="{ hover }" >
        <v-card :class="{ 'md-card-size': $vuetify.breakpoint.mdAndUp, 'sm-card-size': $vuetify.breakpoint.smAndDown }" :color="hover? '#053039' : 'aliceblue'"  elevation="24" :loading="loading" class="mx-auto my-12 d-flex justify-center align-center flex-column" style="border-radius: 25px 25px 25px 0px; transition: all .2s normal linear;">

    <v-row class="d-flex justify-center align-center">
        <v-img alt="web development" class="mt-5" :class="{'mb-6':$vuetify.breakpoint.smAndDown}" style="margin-right: 0px; transition: all .2s normal linear" :style="{ 'height': $vuetify.breakpoint.smAndDown ? '115px' : '160px', 'width' : $vuetify.breakpoint.smAndDown ? '145px' : '210px' }" :src=card.img></v-img>
    </v-row>

    <p class="arabic text-centerd  mb-0" :style="{ 'color': hover ? '#b8c3ca' : '#005f9e' }" style="text-align: center; font-size: small;">
       {{ card.sm_title }}
      </p>
    <p class="arabic text-centerd mb-0 mx-2" :style="{ 'color': hover ? 'aliceblue' : '#005f9e' , 'font-size':$vuetify.breakpoint.smAndDown? 'large' : 'x-large'}" style="text-align: center; letter-spacing: -0.5px;">
      {{ card.title }}
      <v-icon class="mr-2" size="30px" :style="{ 'color': hover ? '#FFFF00' : '#005f9e', 'opacity': hover ? '100%' : '80%' }">mdi-check-decagram</v-icon></p>

    <v-card-text>
      <div class="arabic text-centerd" :class="{'px-2': $vuetify.breakpoint.smAndDown, 'px-15': $vuetify.breakpoint.mdAndUp}" :style="{ 'color': hover ? '#b8c3ca' : 'gray' , 'font-size':$vuetify.breakpoint.smAndDown? 'x-small' : '' }" style="text-align: center;">
        {{ card.describtion }}
     </div>
    </v-card-text>

    <v-divider height="10px" width="490px" class="card-divider"></v-divider>

    <v-card-title class="arabic text-center mb-n3 tecs" :style="{ 'color': hover ? 'aliceblue' : 'black' }">تقنيات عملنا</v-card-title>

    <div>
      <v-chip-group >
        <v-chip class="arabic text-centerd mx-1 ma-0" small dark style="opacity: 70%;" :style="{ 'background-color': hover ? 'gray' : 'black' }">{{ card.chip1 }}</v-chip>

        <v-chip class="arabic text-centerd mx-1 ma-0" small dark style="opacity: 70%;" :style="{ 'background-color': hover ? 'gray' : 'black' }">{{ card.chip2 }}</v-chip>

        <v-chip class="arabic text-centerd mx-1 ma-0" small dark style="opacity: 70%;" :style="{ 'background-color': hover ? 'gray' : 'black' }">{{ card.chip3 }}</v-chip>
      </v-chip-group>
    </div>

    <v-card-actions>
      <v-btn @click="cardValueL(i)" outlined rounded width="200px" height="40px" text class="arabic text-centerd  mb-3" :style="{ 'color': hover ? 'aliceblue' : '#005f9e', 'border-color': hover ? 'aliceblue' : '#005F9E' }" style="letter-spacing: 0.3px; font-size: 16px;">
        اطلب الآن<v-icon class="mr-1 z" small>mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
        </v-card>
      </v-hover>
    </div>
    </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    cardValueR (index) {
      this.selectedCard = this.right_cards[index].sm_title
      this.$store.state.requestedService = this.selectedCard
      this.$router.push('/contact-us')
    },
    cardValueL (index) {
      this.selectedCard = this.left_cards[index].sm_title
      this.$store.state.requestedService = this.selectedCard
      this.$router.push('/contact-us')
    }
  },
  data () {
    return {
      selectedCard: '',
      cardValue: this.$store.state.requestedService,
      right_cards: [
        {
          label: true,
          img: require('../assets/apps developments.svg'),
          sm_title: 'تصميم التطبيقات',
          title: 'تطوير تطبيقات الجوال الحصرية',
          describtion: 'استفد من قوة تطبيقات الجوال مع خدمات راقي المتقدمة يقوم فريقنا الماهر بتحويل أفكارك إلى واقع، حيث يتم إنشاء تطبيقات قوية وسهلة الاستخدام لنظامي التشغيل iOS و Android نحن نقدم لك حلول شاملة مخصصة لنجاح عملك',
          chip1: 'UI/UX',
          chip2: 'Flutter',
          chip3: 'Dart'
        },
        {
          label: true,
          img: require('../assets/automation dev.svg'),
          sm_title: 'أتمتة المواقع واستخراج البيانات',
          title: 'تطوير آليات الذكاء الاصطناعي (البوتات)',
          describtion: 'في راقي نحن متخصصون في تقديم خدمات أتمتة الويب المتطورة لتبسيط عملياتك عبر الإنترنت وتحسينها ومن خلال أتمتة المهام المتكررة وسير العمل نساعدك على توفير المال/الوقت وتقليل الأخطاء وزيادة الإنتاجية. استكشف إمكانيات جديدة لعملك من خلال خدمات أتمتة الويب الموثوقة',
          chip1: 'Python',
          chip2: 'Selenium',
          chip3: 'Pandas'
        },
        {
          label: false,
          img: require('../assets/graphics service.svg'),
          sm_title: 'تصميم الغرافيكس',
          title: 'كافة خدمات تصميم الغرافيكس',
          describtion: 'خدمة تصاميم الغرافيكس لأعمالك الالكترونية هي خدمة مهمة وضرورية لأي موقع يسعى لجذب الزوار وتحسين تجربتهم. فالتصاميم الجذابة والاحترافية تلعب دوراً كبيراً في جعل موقعك يبرز بين منافسيه ويجذب المستخدمين',
          chip1: 'Scrapy',
          chip2: 'Selenium Headless',
          chip3: 'Json'
        }
      ],
      left_cards: [
        {
          img: require('../assets/website_development.svg'),
          sm_title: 'تصميم المواقع',
          title: 'بناء مواقع الكترونية لكافة الاعمال',
          describtion: 'أول ما يراه المستخدمون في مشروعك هو الواجهات الأمامية ولجماليتها أهمية كبيرة في جذب الزبائن, في راقي نستمتع بانتاج واجهات مستخدم جميلة بتطوير احترافي, يقوم فريقنا الماهر بإنشاء تطبيقات ويب جذابة واستجابية على جميع المنصات',
          chip1: 'HTML/CSS',
          chip2: 'JavaScript',
          chip3: 'Vue.js'
        },
        {
          img: require('../assets/dashboard dev.svg'),
          sm_title: 'تطوير لوحات التحكم',
          title: 'تطوير لوحات التحكم الذكية',
          describtion: 'هل تبحث عن خدمات تطوير لوحة تحكم ذكية متخصصة لدفع أعمالك إلى الأمام؟ لا مزيد من البحث! في راقي نحن متخصصون في إنشاء لوحات معلومات ديناميكية مصممة خصيصًا لتلبية احتياجات عملك الفريدة.نوفر لك بيانات في الوقت الفعلي تمكنك من اتخاذ قرارات حكيمة وفورية',
          chip1: 'Laravel',
          chip2: 'Django',
          chip3: 'Bootstrap'
        },
        {
          img: require('../assets/servers service.svg'),
          sm_title: 'خدمة السيرفرات',
          title: 'خدمات الاستضافات والسيرفرات',
          describtion: ' تقدم شركتنا خدمات استضافة مواقع الإنترنت وسيرفرات قوية وموثوقة تضمن للعملاء أداء ممتاز وأمان عالي. كما نقدم دعم فني على مدار الساعة لضمان استقرار وسلامة المواقع الإلكترونية.',
          chip1: 'Cpanel',
          chip2: 'International Services',
          chip3: 'Database'
        }
      ]
    }
  }
}
</script>

<style>
.md-card-size {
  max-width: 90% ;
  height: 565px;
}
.sm-card-size {
  max-width: 60%;
  height: 100%;
}
.steps .left_to_right_apperance {
    -webkit-transition: all 2.5s ease-in-out;
    -o-transition: all 2.5s ease-in-out;
    transition: all 2.5s ease-in-out;
}
.left_to_right_apperance.animated {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0% 100%);
    clip-path: polygon(0 0,100% 0,100% 100%,0% 100%);
}
.our-service-card{
  border-radius: 0px 25px 0px 25px; transition: all .2s normal linear;
}
.label{
right: -10px; position: absolute; top: -50px; background-color: #005f9e; color: aliceblue; border-radius: 10px 10px 10px 0px; height: 60px; width: 120px;
}
.card-divider{
  opacity: 50%; background-color: gray;
}
.tecs{
  font-size: 18px; opacity: 80%;
}
</style>
